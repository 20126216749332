import { Slick } from '../slick';

class Interiors {
    constructor() {
        this.init();
    }

    init() {
        jQuery('[data-trigger="accordion"]').on('click', function () {
            jQuery(this).toggleClass('interiors-accordion__title--active');
            jQuery(this).next().toggle();
        });

        jQuery('[data-trigger').removeClass(
            'interiors-accordion__title--active'
        );
        jQuery('[data-item').css({ display: 'none' });

        jQuery('[data-trigger')
            .first()
            .addClass('interiors-accordion__title--active');
        jQuery('[data-item').first().css({ display: 'flex' });

        const slickShow = new Slick("[data-slider='show']", {
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '250px',
            dots: false,
            prevArrow: document.querySelector(
                '.interiors-gallery__slide--prev'
            ),
            nextArrow: document.querySelector(
                '.interiors-gallery__slide--next'
            ),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        adaptiveHeight: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: true,
                        centerPadding: '250px',
                        dots: false,
                        prevArrow: document.querySelector(
                            '.interiors-gallery__slide--prev'
                        ),
                        nextArrow: document.querySelector(
                            '.interiors-gallery__slide--next'
                        )
                    }
                },
                {
                    breakpoint: 901,
                    settings: {
                        adaptiveHeight: true,
                        infinite: true,
                        dots: true,
                        slidesToShow: 1,
                        centerMode: false,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        AOS.init({
            useClassNames: true,
            offset: 100,
            disable: 'mobile'
        });
    }
}

export { Interiors };
