import { Hamburger } from "./hamburger";

class Header {
  constructor(container, scrollEvent) {
    this.container = container;
    this.scrollEvent = scrollEvent;
  }

  init() {
    if (this.scrollEvent) {
      this.scrollEvent();
    }
    new Hamburger(
      document.querySelector("[data-element='menu-btn']"),
      document.getElementById("primary"),
      this.container,
      true
    ).init();
  }

  scrollEvent() {
    let container = this.container;
    document.addEventListener("scroll", function () {
      let lastKnownScrollPosition = window.scrollY;
      if (lastKnownScrollPosition > 0) {
        container.classList.add("scrolled");
      } else {
        container.classList.remove("scrolled");
      }
    });
  }
}

export { Header };
