import { Header } from './modules/header';
import { Footer } from './modules/footer';
import { Home } from './modules/pages/home';
import { Amenities } from './modules/pages/amenities';
import { Floorplans } from './modules/pages/floorplans';
import { Interiors } from './modules/pages/interiors';
import { Neighbourhood } from './modules/pages/neighbourhood';
import { Gallery } from './modules/pages/gallery';
import { About } from './modules/pages/about';
import smoothscroll from 'smoothscroll-polyfill';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class App {
    constructor(page, initialized) {
        this.page = page;
        this.initialized = initialized;
        gsap.registerPlugin(ScrollTrigger);

        if (this.initialized == false) {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', this.init());
            } else {
                this.init();
            }
        }
    }

    init() {
        this.initialized = true;
        smoothscroll.polyfill();
        new Header(document.getElementById('header'), false).init();
        new Footer().init();
        switch (true) {
            case this.page.classList.contains('home'):
                new Home();
                break;
            case this.page.classList.contains('floorplans'):
                new Floorplans();
                break;
            case this.page.classList.contains('amenities'):
                new Amenities();
                break;
            case this.page.classList.contains('interiors'):
                new Interiors();
                break;
            case this.page.classList.contains('neighbourhood'):
                new Neighbourhood();
                break;
            case this.page.classList.contains('gallery'):
                new Gallery();
                break;
            case this.page.classList.contains('about'):
                new About();
                break;
        }
    }
}

new App(document.querySelector('main'), false);
